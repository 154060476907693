import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from '@/plugins/vuetify'
import { i18n } from '@/plugins/i18n'

import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'

import services from '@/services'

Object.entries(services)
  .forEach(({ 1: service }) => Vue.use(service))


// const apiUrl = `http://192.168.86.200:8087/api/graphql`
const apiUrl = process.env.API_URL

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: apiUrl
})

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
})

import VueApollo from 'vue-apollo'

Vue.use(VueApollo)
const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})

Vue.config.productionTip = false


if(process.env?.LOG_ENV_VARS)
  console.dir(process.env)


new Vue({
  router,
  vuetify,
  i18n,
  apolloProvider,
  render: h => h(App),
}).$mount('#app')
