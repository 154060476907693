<template>
    <div>
        <div class="mb-8">
            <v-btn @click="goTop()" class="py-10 back-btn float-scroll-btn">
                <img style="transform: rotate(90deg); height: 50px;" class="my-4 mx-4" :src="require(`@/assets/buttons/scroll.svg`)" />
            </v-btn>
        </div>
        <!-- <div>
            <v-btn @click="goTop()" class="py-10 back-btn float-scroll-btn">
                <img style="transform: rotate(-90deg)" class="my-4 mx-4" :src="require(`@/assets/buttons/scroll.svg`)" />
            </v-btn>
        </div> -->
    </div>
</template>

<script>
export default {
    name: "ScrollButtons",
    methods: {
        goTop() {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }
    }
}
</script>
<style scoped>
.float-scroll-btn {
	position: fixed;
	bottom: 40px;
    right: 60px;
    width: auto;
    background-color: #FFF !important;
    z-index: 1;
}
</style>
