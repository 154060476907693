<template>
    <v-app id="app">
      <v-main :class="$route.fullPath !== '/urbani-laboratorij' ? 'mx-16 mt-16' : ''">
        <transition name="fade-x" mode="out-in">
          <router-view style="margin-bottom: 150px;" :key="$route.fullPath"></router-view>
        </transition>
      </v-main>
      <v-row class="mx-4 my-8 mx-16" v-if="showButtons" width="auto">
          <home-button />
          <v-spacer></v-spacer>
          <scroll-buttons v-if="showScrollButtons" style="z-index: 3;"/>
      </v-row>
    </v-app>
</template>

<script>
  import 'vue-transitions-css';
  import HomeButton from './components/HomeButton.vue';
  import ScrollButtons from './components/ScrollButtons.vue';

  export default {
    components: { HomeButton, ScrollButtons },
    name: 'App',
    data() {
      return {
        showScrollButtons: false,
        timeout: null,
      }
    },
    computed: {
      showButtons() {
        if(this.$route.fullPath == '/' || this.$route.fullPath == '/projects') return false
        
        return true
      },
    },
    mounted() {
      this.addEventsListener()
    },
    methods: {
      handleScroll() {
        this.showScrollButtons = false
        if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) 
          this.showScrollButtons = true
      },
      resetTimer() {
        if(this.timeout != null) {
          window.clearTimeout(this.timeout)
          this.setTimer()
        }
      },
      setTimer() {
        this.timeout = window.setTimeout( () => {
          if(this.$route.fullPath !== '/') {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'event': 'home_page_reset',
            })

            this.$router.push({ path: '/'})
          }
        }, 5 * 60 * 1000)

      },
      addEventsListener() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('mousemove', this.resetTimer);
        window.addEventListener('mousedown', this.resetTimer);
        window.addEventListener('keypress', this.resetTimer);
        window.addEventListener('touchstart', this.resetTimer);
        window.addEventListener('click', this.resetTimer);
        this.setTimer()
      }
    }
  }
</script>

<style>
  @import '../src/assets/styles/style';
</style>

<style lang="sass">
  @import '../src/assets/styles/styles'
</style>
