export default {
  key: 'tileService',
  install(app) {
    // const tileServer = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';

    // eslint-disable-next-line no-param-reassign
    app.prototype.$tileService = {
      url: `${process.env.TILE_URL}/{z}/{x}/{y}.png`,
      attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    }
  },
}

export const _ = null
