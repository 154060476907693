/* eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

Vue.config.productionTip = false


const routes = [
  {
    path: '/',
    component: () => import('../views/HomePage.vue')
  },
  {
    path: '/smart-ri',
    component: () => import('../views/SmartRi.vue')
  },
  {
    path: '/projects',
    component: () => import('../views/CekomProjects.vue'),
  },
  {
    path: '/projects/:projectName',
    component: () => import('../views/ProjectPage')
  },
  {
    path: '/projects/:projectName/:id',
    component: () => import('../views/ProductView')
  },
  {
    path: '/rijeka',
    component: () => import('../views/GradRijeka')
  },
  {
    path: '/payphones',
    component: () => import('../views/PayphonesView')
  },
  {
    path: '/urbani-laboratorij',
    component: () => import('../views/UrbanLaboratoryView')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
})

export default router