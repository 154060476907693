
export default {
    about_project: "About project",
    about_projects: "ABOUT PROJECTS",
    find_more: "Find out more about each project",
    products: "Products / services",
    partners: "Partners",
    projects: "Projects",
    tariff: "Tariff",
    gender: "Gender",
    female: "Female",
    male: "Male",
    private: "Private Tariff",
    business: "Business Tariff",
    choose_filters: "Choose filters for map view:",
    humidity: "Air Humidity",
    temperature: "Air Temperature",
    carbon_monoxide: "Carbon monoxide",
    sulphur_dioxide: "Sulphur dioxide",
    nitrogen_monoxide: "Nitrogen monoxide",
    nitrogen_dioxide: "Nitrogen dioxide",
    ozone: "Ozone",
    particulates_1: "Particulates 0.1",
    particulates_25: "Particulates 2.5",
    particulates_10: "Particulates 10",
    updated: "Updated",
    choose_data_filters: "Select data type to display on the map",
    data_type_levels: "Measurement data level",
    low: "Low",
    mid: "Medium",
    hi: "High",
    not_dangerous: "Safe",
    dangerous: "Hazardous",
    AQI: "Air Quality Index",
    AQI_good: "Good",
    AQI_moderate: "Moderate",
    AQI_unhealty_for_sensitive_groups: "Unhealthy for sensitive groups",
    AQI_unhealthy: "Unhealthy",
    AQI_very_unhealthy: "Very Unhealthy",
    AQI_hazardous: "Hazardous",
    eu: `The development of the application was co-financed by the European Union from the European Regional Development Fund`,
    other: "Other",
    events: "Events",
    grad_rijeka: "City of Rijeka",
    grad_rijeka_info: `Rijeka is the largest Croatian port and the third largest city in the Republic of Croatia, and the administrative center of the Primorje - Gorski Kotar County. A turbulent history has directed the city of Rijeka towards multiculturalism, tolerance and openness, and it is the symbol of the port and the diversity of its citizens that most strongly represents the spirit of Rijeka . Today Rijeka is a modern city with rich industrial heritage, developed cultural and tourist offer, a start-up community and the new student campus in Trsat.`,
    total_area: 'Total area',
    population: 'Population',
    av_annual_temp: 'Average annual temperature:',
    av_humidity: 'Average humidity',
    precipitation_in_year: 'Precipitation in year',
    rainy_days_in_year: 'Rainy days per year',
    position: '',
    sea_temp_in_summer: '',
    inhabitants: 'inhabitants',
    about_rijeka: 'About Rijeka',
    payphone: 'Payphone',
    payphones: 'Payphones',
    active_payphones: 'The list of active payphones in Rijeka',
    web_app: 'Web application',
    mobile_app: 'Mobile application',
    change_dark_light_mode: 'Choose light or dark mode',
    dark: 'Dark Mode',
    light: 'Light Mode',

    // Urbani laboratorij 
    urban_laboratory: 'Urban laboratory',
    urban_laboratory_about: `Building a smart city does not work without smart and engaged citizens who understand the
				challenges facing their city and want to actively participate in developing technological solutions.
				Through the Urban Laboratory, one of the activities of the CEKOM for smart cities project, we will
				initiate a two-way communication with citizens. The first step of this communication is to involve
				citizens in the testing of our future products and services in order to get feedback on the results
				of the project. By participating in the tests and subsequently using CEKOM products and services,
				the citizens of Rijeka will be able to participate in decision-making regarding the processes that
				affect their daily lives, which we hope will lead to a stronger sense of connection with the local
				administration and the broader community.`,
	urban_laboratory_sign_up: 'Getting involved?',
	urban_laboratory_phys: 'Urban Laboratory Spaces',
	urban_laboratory_phys_about: `Our Test and Demonstration Center at Trg Svete Barbara 2 is open every weekday from 8
				a.m. to 4 p.m., and on Thursdays until 6 p.m.
				Smart RI staff will present to interested citizens all of the project's products and services,
				as well as answer all of their questions, using video and online materials.
				Citizens will be able to learn about the CEKOM project in the premises of the RiHUB,
				which is the ideal location for the presentation of the project Competence Center for Smart
				Cities, in addition to the SmartRI company's premises.
				Events related to the CEKOM project for Smart Cities and public presentations of the
				project take place in the RiHub, in addition to a total of 4 Smart TVs right at the entrance
				of the space, on which project presentations, visualizations, and videos are shown.`,
	urban_laboratory_online: 'Urban Laboratory Online',
	urban_laboratory_online_about: `Many of the applications being developed as part of the CEKOM project for smart cities
				will be aimed specifically at citizens.
				
				The apps will allow you to track traffic in real time, receive utility notifications and
				information, and plan your stay in a city or destination.
				It is critical that you test the applications under development and provide suggestions and
				comments, as well as your opinion on their functionalities, during this final phase of the
				project.
				The CEKOM Questionnaire allows you to become acquainted with all aspects of our
				project and express your thoughts on them.
				Your feedback and evaluations will be used to improve the product's performance and to
				determine future directions for the development of Rijeka as a smart, digitally ready, and
				open city.`,

    smart_ri_info: `The Center of Competence for Smart Cities is a three-year project of national importance, and at the same time the largest development project in Croatia in the field of information and communication solutions, aimed at improving the quality of life of citizens. The project implies a systematic and innovative approach to the development of the city, management of its resources, processes and services, with the help of new technologies. This raises the quality of life of citizens and visitors, and enables smart decision-making and management, as well as increased efficiency in terms of planning and achieving savings. Unnecessary waste of the public funds will be avoided, as well as inadequate quality of the public goods and services.

            In addition to making optimal use of resources, a smart city simultaneously encourages citizens, communities, scientific research institutions, and entrepreneurs to use the collected data, which increases the possibility of participation in detecting problems and finding solutions. A smart city is also flexible, open, accessible, and focused on citizens, and in the long run it attracts investment, encourages entrepreneurship, increases productivity, and democracy among other things.
    
            The Center of Competence for Smart Cities (CEKOM) implies a partnership in the innovation cluster, which connects economic entities and research institutions on research and development projects in smart cities. The goal is to solve the challenges that cities face, such as transport, energy, ecology, infrastructure, resource management, etc.  In the innovation cluster that should make Rijeka the smartest city in Croatia, 20 partners are gathered.
    
            The holder and the manager of the project, the Center of Competence for Smart Cities is the company Smart RI Ltd. which was founded by the City of Rijeka. Smart RI, and thus the city of Rijeka, will become a test and demonstration center for “smart” technologies, where the pilot projects will be implemented to improve products and/or services to the level of readiness for commercialization. It is this context of the urban laboratory that will be most visible to the citizens and visitors of Rijeka, who will, for the first time, have the opportunity to actively participate in the application and development of the most advanced technologies. This will position Rijeka as a development center and an optimal location for the long-term research projects.
    
            Furthermore, within the management function, Smart RI Ltd. provides administrative, organizational, and logistic support to other CEKOM members.`,
    smart_ri_title: "About Us",

    // Project Info
    energy_info: `The goal of this project unit is to develop solutions for the improvement of energy systems and environmental monitoring systems in smart cities. The desire is to make cities as energy efficient and sustainable as possible in resource management, protecting the environment and maintaining the quality of life of citizens, relying on data collected through the core platform of a smart city. Sustainable management of natural resources (Smart Environment) is achieved through the introduction of new technological solutions. `,
    dii_info: `The 4DII (Intelligent Infrastructure) project starts from the premise that utility infrastructure data collected and edited is an important set of data in the efficient management of systems that base their services on infrastructure security and availability. The goal of this project is efficient recording and management of underground and aboveground communal infrastructure, in order to reduce costs, increase efficiency, attract investments and provide better service.`,
    living_info: `The aim of this project is to connect directly with citizens and life in the city, and the movement of residents and visitors – this adds a direct value for the citizens. Within Smart Lifestyle, projects related to improving the quality of life in various elements have been included- housing, education, security, social inclusion, culture, sports and recreation, and sustainable tourism.

            The leading partner is Hrvatski Telekom, and Alarm Automatika, Creative Department, Combis, iOLAP, and the Faculty of Tourism and Hospitality Management are also participating in the project.`,
    modesty_info: `The central development activity of the Modesty project is the development of the Big Data system and a set of advanced analytical models. Data on the state of the environment and population activities will be collected in real time, all in order to obtain the most relevant sample of data for research purposes, thus this project creates a platform for cooperation between the city, industry, science and population. In addition to data from hardware sensors, data from the mobile network of the operator on the movement and retention of residents will be collected.`,
    surinmo_info: `The aim of the project is to develop a platform for managing the networks of publicly available electric vehicle charging stations, and to develop and test the eSigns system and the eCrossroads system of the Rijeka City Traffic Center, which would also be connected to the future SmartCity.SURINMO platform. The Smart Economy area includes projects related to improving business through innovation, which results in increased productivity. Projects and products optimize traffic flows and relieve traffic at rest, so visible results in traffic quality are expected, from safety to information. This will apply in particular to cases of traffic diversion in case of accidents, weather disasters, road works and the like.`,
    connectedTraffic_info: `The Connected.Traffic project aims to improve the quality of life of citizens by establishing better solutions in urban and multimodal transport and by promoting and encouraging sustainable, clean and energy efficient modes of transport. The advanced information and communication system that is being developed within this project will serve as a support to decision-making and in the management of urban mobility.`,
    
    // Energy Products Info
    energy_name_one: "Energy Efficiency",
    energy_name_two: "EmPower",
    energy_name_three: "SmartWaste",
    energy_name_four: "KPI Evaluator",
    energy_name_five: "SmartLighting Network",

    energy_info_one: `EnergyEfficiency is a platform that enables energy consumption and energy efficiency monitoring in all energy consumption sectors, as well as planning of energy efficiency implementation in a smart city`,
    energy_info_two: `<b>Smartis</b> is a growing technology company developing an innovative Digital Twin Platform solution. It is a solution that connects all important physical facilities in the municipality of Rijeka in one place in a digital copy. The platform enables monitoring of characteristics of buildings and key energy measurements in real time. By including various stakeholders in the platform, we want to offer users better information when making decisions on reducing the carbon footprint in Smart City Rijeka. The platform also serves as a basis for empowerment and activation of end users of energy and other natural resources, through which citizens will have the opportunity to directly participate in the development of models of energy efficiency and sustainability of the smart city.`,
    energy_info_three: `Through mobile and web applications developed by Hrvatski Telekom, utility companies in charge of waste disposal, dispatchers who plan daily waste disposal routes and citizens will be connected`,
    energy_info_four: `KPI Evaluator is an innovative and interactive presentation platform that brings together selected key performance indicators for monitoring urban development. It allows their interpretation and comparison with each other and serves as basis for data-based decision making for city administrations.`,
    energy_info_five: `Elektrokovina plus is developing a complete solution for efficient management of the public lighting system and supporting infrastructure.`,

    // Modesty Products Info
    modesty_name_one: `Roads`,
    modesty_name_two: 'Donte',
    modesty_name_three: 'eCompetitions',
    modesty_name_four: 'eView',
    modesty_name_five: 'Meer',
    modesty_name_six: 'Pametna destinacija',
    modesty_name_seven: 'Picolus',
    modesty_name_eight: 'RiLive',
    modesty_name_nine: 'Teddy',
    modesty_name_ten: 'Votmax',
    modesty_name_eleven: 'Zanty',

    modesty_info_one: `The goal of this mobile application is to inform traffic users about various changes in traffic during the ride itself.`,
    modesty_info_two: `Donte is a module that enables you to generate a statistics report about the traffic on a specific location over a period of time; based on demographics, time spent on site, number of people on site. Demographic statistics include information about visitors' gender, age, country of residence. All the data used in this project is anonymous.`,
    modesty_info_three: `The purpose of this web application is to provide information on city, county and other tenders. `,
    modesty_info_four: `eView is a city portal for open data, a platform that aims to encourage cooperation between the city, industry, the scientific community and the population. eView allows you to view the API and open documentation of projects realized as part of CEKOM.`,
    modesty_info_five: `Using the anonymized data from the telecom infrastructure and thanks to advanced algorithms, Meer as a module is showing us the approximate direction from which a group of users came to a certain location, the approximate direction in which they are moving as well as the average speed of their movement. The heatmap shows us where the largest number of users is, by changing the time parameter we can as well estimate the duration of the users’ stay in a location.`,
    modesty_info_six: `Various reports based on the collected data enable smart destination management (Data Driven Destination Management). The idea is to develop more detailed analytics based on existing and new data sources, and for the purpose of monitoring and optimizing the movement of domestic and foreign citizens and visitors to the city and visualization of the same for real-time conclusions.`,
    modesty_info_seven: `Picouls report, unlike Donte, as a source of information uses the data from the so called Pico cells. They offer somewhat more precise information on the number of users on a specific location. Within the 150 m radius. In order to generate a Picolus report we need to set the time frame and choose a site of our interest where Pico cells are located. The report is then generated together with a map.`,
    modesty_info_eight: `The aim of this application is to present in one place data of public interest related to life in the city of Rijeka (forecast, public transport, traffic, entertainment and culture, epidemiological data, news…) for the search day (therefore the name of the application RiLive - Rijeka NOW).`,
    modesty_info_nine: `Teddy is a heatmap that shows us the number of network users on different locations in the city of Rijeka. This module enables us to choose which groups of users we wish to have represented in the map. There is a set of filters to choose from besides the time frame - gender, age, tariff (private or business) and nationality. As we’ve chosen the filters, the heat map is shown accordingly. The data being used is fully anonymized.`,
    modesty_info_ten: `Votmax is a machine learning project. The software connected to a traffic camera is able to count the number of vehicles on the road or in marine traffic and deduct which vehicle is in question: a car, a van, a truck, or a motorcycle. The data is shown in a line graph and in a table.`,
    modesty_info_eleven: `Zanty module uses the data collected from the air sensors installed around the city of Rijeka. The data related to the specific location is shown on the map, the colour signifies the level of pollution. The data is also presented in the table below the map.`,

    // Living Products Info
    living_name_one: `Multifunctional device for two-way communication between citizens and the City`,
    living_name_two: 'eTinerer - city movement',
    living_name_three: 'Security Barometer',
    living_name_four: 'Rijeka.Info',
    living_name_five: 'Data Driven Destination Management System',
    living_name_six: 'City Smart View',

    living_info_one: `You are using Hrvatski Telekom's multifunctional device which enables two-way communication between citizens and the City of Rijeka. The device connects existing telecommunications services with other innovative services developed in the project, and the application that you are currently using offers you more detailed information and the ability to download developed applications or visit project websites by scanning the QR codes.`,
    living_info_two: `As part of the <b>eTinerer - city movement</b> platform, a mobile <b>etinerary</b> application and an accompanying <b>support web</b> for crowd-sourcing data are being developed.

                <b>etinerary</b> is a virtual personal assistant that tailors personalized tourist routes with the help of artificial intelligence. In doing so, it gives the user a proposal of the optimal next destination and specific activities on it. It allows the user to change plans at any time, is always available and can be used completely free of charge. Its primary task is to maximize customer satisfaction with the destination.
                
                Providers of services and content on sites can enter their offers via the <b>support web</b>, also completely free of charge, and thus direct users to themselves.`,
    living_info_three: `Application for monitoring security parameters in the city of Rijeka
    The application collects, processes and displays on the map of the city of Rijeka information on various events that affect the safety and quality of life of citizens: crime, difficulties in traffic and parking, air quality and other environmental indicators and the functioning of water, electricity and gas . The presented data serve citizens and visitors to choose safer and faster directions of movement, and city services to establish the right places to raise the level of security.
    The application uses data from various public sources and by citizens, and was developed by Alarm Automatika within the CEKOM project Smart City Living with the aim of improving the quality of life of the citizens of Rijeka.`,
    living_info_four: `Rijeka.Info is a mobile application with data on commercial and public offers of the city and their presentation through virtual reality.`,
    living_info_five: `iOLAP with the assistance of the Faculty of Management in Tourism and Hospitality is developing a central repository of content and destination information available on multiple communication channels/resources for guests at the destination.`,
    living_info_six: `City Smart View is the application you are using at the moment! It’s goal is to inform you about the projects that were realized as a part of CEKOM initiative and which aim at turning Rijeka into a smart city. Exploring through this application you will also have a chance to scan the QR codes and either visit the website of certain projects or download mobile apps!`,

    // 4DII Products Info
    dii_name_one: 'Platform 4DII',
    dii_name_two: 'System for automatic processing and recognition of underground infrastructure based on radar records',
    dii_name_three: 'Innovative infrastructure inventory and control system',
    dii_name_four: 'Critical infrastructure facility monitoring system integrated with advanced analytical tools',
    dii_name_five: 'Integrated 4D intelligent infrastructure system',
    
    dii_info_one: `The innovative product developed by Ericsson Nikola Tesla replaces the two-dimensional display of infrastructure with a space-time dimension (4D), and further speeds up the process of data collection and interpretation, thus achieving more efficient records and management of utility infrastructure.`,
    dii_info_two: `Research, formulation and validation of the concept for automatic processing of radar records includes the beginning of the development of ICT solution that will enable faster processing of utility infrastructure data. The integrated system is being developed by the University of Rijeka.`,
    dii_info_three: `3t.cable is developing an ICT solution that will enable more innovative inventory and control of utility infrastructure (i3solutions).`,
    dii_info_four: `Alarm automatika is developing a system for monitoring critical infrastructure facilities, managing in crisis situations and sending mass messages and notifications.`,
    dii_info_five: `The goal for the Smart City 4DII system to enable its modules to inventory, plan, manage, document and analyze utility infrastructure data by integrating different aspects of data.`,

    // Surinmo Products Info
    surinmo_name_one: 'eCrossroads',
    surinmo_name_two: 'eRoaming platform',
    surinmo_name_three: 'eSigns',

    surinmo_info_one: `The eCrossroads project developed by Smart sense is a complex system of highly connected modules at different levels. eCrossroads will primarily increase the safety of pedestrians in traffic, and at the same time will enable smarter planning and management of traffic in urban and other areas. Namely, in addition to advanced traffic monitoring and classification, the system will be integrated with other smart solutions such as smart lighting, and protocols for sending data and alarms and monitoring the system will be developed.`,
    surinmo_info_two: `Hrvatski Telekom is developing a platform with clearly defined locations for charging e-vehicles, with a real-time indication of availability and the possibility of booking and using a charging station, with accurate information and locations for using the Car Sharing system and a fully digitalized parking system, which combines information on available locations for free parking. So far in Croatia, but also in the wider region, there is no single platform that can serve users of parking, e-vehicles and car sharing system, and the consequence of implementing such a system will be strengthening road safety and increasing traffic throughput.`,
    surinmo_info_three: `Exevio's product eSigns will implement sensory solutions that will collect environmental data and provide the prerequisites for analysis. The complete project is based on innovation or a combination of new technologies and a new industry based on the use of eInk. After making the first copies of eSigns and placing them on locations that will be determined in accordance with the priorities of the City, they will be connected to the City Traffic Center, which will be responsible for their management and testing in real operating conditions.`,

    // Connected Traffic Products Info
    connectedTraffic_name_one: 'Platform for data aggregation in the function of decision-making in urban transport and urban mobility',
    connectedTraffic_name_two: 'Monitoring and management system in the function of decision-making in urban transport and urban mobility',
    connectedTraffic_name_three: 'The system of usage and exchange of resources, infrastructure and assets in the field of transport using the paradigm of sharing economy',
    connectedTraffic_name_four: 'Advanced analytical solutions to increase security within smart cities',
    connectedTraffic_name_five: 'Integrated decision support system in urban transport and urban mobility',

    connectedTraffic_info_one: `The platform developed by Ericsson Nikola Tesla collects data in real time. The solution is modular and scalable and enables the integration of a number of sources and the processing of standardized and non-standardized input data.`,
    connectedTraffic_info_two: `The partners have developed a number of advanced analytical solutions to support decision-making in urban transport and urban mobility. The system defines new standard operating procedures that can be activated manually, automatically based on the range of values received from the data aggregation platform and automatically by direct notification.`,
    connectedTraffic_info_three: `Apsolon is working on research and development of the system of sharing resources, infrastructure and assets through the paradigm of the sharing economy "ShaRIng", and as a concept that will be developed later in the project, they have chosen a simpler approach to events.`,
    connectedTraffic_info_four: `Advanced analytical solutions are aimed at establishing a process that, based on the identification of events (especially fire), location and other parameters, on the basis of established values selects a newly defined set of instructions to be executed.`,
    connectedTraffic_info_five: `The system will collect, process and make available all available information related to the transport system in order to better and better manage traffic and sustainable mobility within smart cities. Innovation is contained in a completely new approach to the integration and analysis of big data and their use in real time to manage traffic flows.`,
}