export default {
    about_project: "O projektu",
    about_projects: "O PROJEKTIMA",
    find_more: "Saznaj više o pojedinom projektu",
    products: "Proizvodi / usluge",
    partners: "Partneri",
    projects: "Projekti",
    tariff: "Tarifa",
    age: "Dob",
    gender: "Spol",
    female: "Ženski",
    male: "Muški",
    private: "Privatna tarifa",
    business: "Poslovna tarifa",
    choose_filters: "Odaberite filtere za prikaz na karti:",
    humidity: "Vlažnost zraka",
    temperature: "Temperatura zraka",
    carbon_monoxide: "Ugljikov monoksid",
    sulphur_dioxide: "Sumporov dioksid",
    nitrogen_monoxide: "Dušikov monoksid",
    nitrogen_dioxide: "Dušikov dioksid",
    ozone: "Ozon",
    particulates_1: "Čestice 0.1",
    particulates_25: "Čestice 2.5",
    particulates_10: "Čestice 10",
    updated: "Ažurirano",
    choose_data_filters: "Odaberite podatak za prikaz na karti",
    data_type_levels: "Razina mjernih podataka",
    low: "Niska",
    mid: "Umjerena",
    hi: "Visoka",
    not_dangerous: "Nije štetna",
    dangerous: "Štetna",
    AQI: "Indeks kvalitete zraka",
    AQI_good: "Dobro",
    AQI_moderate: "Prihvatljivo",
    AQI_unhealty_for_sensitive_groups: "Umjereno",
    AQI_unhealthy: "Loše",
    AQI_very_unhealthy: "Vrlo loše",
    AQI_hazardous: "Izuzetno loše",
    eu: `Izrada aplikacije sufinancirana je sredstvima Europske unije iz Europskog fonda za regionalni razvoj.`,
    other: "Ostalo",
    events: "Događaji",
    grad_rijeka: "Grad Rijeka",
    grad_rijeka_info: `Rijeka je najveća hrvatska luka i treći po veličini grad u Republici Hrvatskoj, te administrativni centar Primorsko-goranske županije. Turbulentna povijest usmjerila je grad Rijeku prema multikulturalnosti, tolerantnosti  i  otvorenosti, a upravo je simbol luke i različitost njenih građana ono što najsnažnije predstavlja duh Rijeke. Danas   je   Rijeka   moderni   grad   s   bogatom   industrijskom   baštinom,   razvijenom kulturnom i turističkom ponudom, sportskom infrastrukturom, start-up zajednicom i novim studentskim Kampusom na Trsatu.`,
    total_area: 'Ukupna površina',
    population: 'Stanovništvo',
    av_annual_temp: 'Srednja godišnja temperatura',
    av_humidity: 'Prosječna vlažnost',
    precipitation_in_year: 'Ukupne padaline godišnje',
    rainy_days_per_year: 'Kišnih dana u godini',
    position: 'Položaj',
    sea_temp_in_summer: 'Temperatura mora ljeti',
    inhabitants: 'stanovnika',
    about_rijeka: 'O Rijeci',
    payphone: 'Telefonska govornica',
    payphones: 'Telefonske govornice',
    active_payphones: 'Lista aktivnih telefonskih govornica u Rijeci',
    web_app: 'Web aplikacija',
    mobile_app: 'Mobilna aplikacija',
    change_dark_light_mode: 'Odaberite svijetlu ili tamnu temu aplikacije',
    dark: 'Tamna tema',
    light: 'Svijetla tema',

    // Urbani laboratorij 
    urban_laboratory: 'Urbani laboratorij',
    urban_laboratory_about: `Uspostava pametnog grada ne funkcionira bez pametnih i angažiranih građana koji
                    razumiju izazove s kojima se njihov grad nosi i koji se žele aktivno uključiti u razvoj
                    pojedinačnih tehnoloških rješenja. Kroz otvaranje Urbanog laboratorija, jednu od
                    projektnih aktivnosti CEKOM-a za pametne gradove, pokrenut ćemo dvosmjernu
                    komunikaciju s građanima. Prvi korak te komunikacije je uključenje građana u testiranje
                    naših budućih proizvoda i usluga kako bismo dobili povratne informacije na rezultate
                    projekta Centar kompetencija za pametne gradove. Sudjelovanjem u testiranju, a zatim i u
                    korištenju proizvoda i usluga CEKOM-a, građanke i građani Rijeke moći će se uključiti i u
                    donošenje odluka oko procesa koji utječu na njihov svakodnevni život, što će, nadamo se,
                    rezultirati i snažnijim osjećajem povezanosti s lokalnom upravom i širom zajednicom.`,
    urban_laboratory_sign_up: 'Kako se uključiti?',
    urban_laboratory_phys: 'Fizički prostori Urbanog Laboratorija',
    urban_laboratory_phys_about: `Vrata našeg testnog i pokaznog centra na adresi Trg Svete Barbare 2 otvorena su svakog
                        radnog dana od 8 do 16 sati, a četvrtkom do 18 sati. Uz pomoć video i online materijala
                        djelatnici Smart RI-ja će zainteresiranim građanima predstaviti sve proizvode i usluge koje
                        nastaju kroz projekt te će odgovoriti na sva njihova pitanja.
                        Osim u prostoru tvrtke SmartRI građani će sve o CEKOM projektu moći saznati i u
                        prostoru RiHUB-a koji je idealna lokacija za prezentaciju projekta Centar kompetencija za
                        pametne gradove. Osim sveukupno 4 Smart TV-a smještenih na samom ulasku u prostor,
                        na kojima će se prikazivati prezentacije projekta, vizualizacije te videi, u prostoru RiHuba
                        će se odvijati i događanja vezana uz projekt CEKOM za pametne gradove te javne
                        prezentacije projekta.`,
    urban_laboratory_online: 'Online okruženje Urbanog Laboratorija',
    urban_laboratory_online_about: `Brojne aplikacije koje se razvijaju u sklopu projekta CEKOM za pametne gradove bit će
                        namijenjene upravo građanima. Aplikacije će Vam u realnom vremenu omogućiti praćenje
                        situacije u prometu, praćenje komunalnih obavijesti i informacija te planiranje boravka u
                        nekom gradu ili na destinaciji. U ovoj, završnoj fazi projekta bitno nam je da upravo vi
                        testirate aplikacije u razvoju i date prijedloge i komentare te svoje viđenje njihovih
                        
                        funkcionalnosti.
                        Kroz CEKOM Upitnik se možete upoznati sa svim segmentima našeg projekta i izraziti
                        svoje mišljenje o njima. Vaša će mišljenja i ocjene biti korištene za unaprjeđivanje
                        performansi proizvoda te određivanje daljnjih smjerova razvoja grada Rijeke kao
                        pametnog, digitalno spremnog i otvorenog grada.`,
    


    smart_ri_info: `Centar kompetencija za pametne gradove je trogodišnji projekt od nacionalnog značaja, a ujedno i najveći razvojni projekt u Hrvatskoj u području informacijsko komunikacijskih rješenja usmjerenih na poboljšanje kvalitete života građana.

            Projekt podrazumijeva sustavni i inovativni pristup razvoju grada, upravljanju njegovim resursima, procesima i uslugama uz pomoć novih tehnologija. Time se podiže kvaliteta života građana i posjetitelja, a omogućuje i pametno donošenje odluka i upravljanje te pojačana efikasnost u smislu planiranja i ostvarivanja ušteda. Izbjeći će se i nepotrebno rasipanje javnih sredstava ali i neadekvatna kvaliteta javnih dobara i usluga.
    
            Osim što optimalno koristi resurse, pametan grad istodobno ohrabruje građane, zajednice, znanstveno-istraživačke institucije i poduzetnike na korištenje prikupljenih podataka čime se pojačava mogućnost participacije u detektiranju problema i pronalaženju rješenja. Pametan grad je i fleksibilan, otvoren, pristupačan i fokusiran na građane, a dugoročno privlači investicije, potiče poduzetništvo, povećava produktivnost i demokratičnost…
    
            Centar kompetencija za pametne gradove (CEKOM) podrazumijeva partnerstvo u inovacijskom klasteru koji povezuje gospodarske subjekte i istraživačke institucije na projektima istraživanja i razvoja u pametnim gradovima. Cilj je rješavanje izazova s kojima se gradovi nose, poput prometa, energetike, ekologije, infrastrukture, upravljanja resursima… U inovacijskom klasteru koji bi od Rijeke trebao stvoriti najpametniji grad u Hrvatskoj okupljeno je 20 partnera.
    
            Nositelj i prijavitelj projekta Centra kompetencija za pametne gradove je trgovačko društvo Smart RI d.o.o. koje je osnovao Grad Rijeka. Smart RI, a time i grad Rijeka, će postati testni i pokazni centar za „pametne“ tehnologije, u kojem će se provoditi pilot-projekti radi unapređenja proizvoda i/ili usluga do razine spremnosti za komercijalizaciju.
    
            Upravo će taj kontekst urbanog laboratorija biti najvidljiviji građanima i posjetiteljima Rijeke, koji će po prvi put imati priliku aktivno sudjelovati u primjeni i razvoju najnaprednijih tehnologija. Time će se Rijeka pozicionirati kao razvojni centar i optimalna lokacija za dugoročne istraživačke projekte.
    
            Nadalje, u okviru upravljačke funkcije, Smart RI d.o.o. pruža administrativnu, organizacijsku i logističku podršku ostalim članovima CEKOM-a.`,
    smart_ri_title: "O nama",

    // Project Info
    energy_info: `Cilj ove projektne cjeline je razvoj rješenja za unaprijeđenje energetskih sustava i sustava za praćenje okoliša u pametnim gradovima. Želja je učiniti gradove što je moguće više energetski učinkovitim i što više održivim u gospodarenju resursima, štiteći okoliš i održavajući kvalitetu života građana, oslanjajući se na podatke prikupljene kroz temeljnu platformu pametnog grada. Održivo upravljanje prirodnim resursima (Pametan okoliš) postiže se putem uvođenja novih tehnoloških rješenja.`,
    dii_info: `Projekt 4DII (Inteligentna infrastruktura) kreće od pretpostavke kako su prikupljeni i uređeni podaci o komunalnoj infrastrukturi važan skup podataka u učinkovitom upravljanju sustavima koji svoje usluge baziraju na sigurnosti i raspoloživosti infrastrukture. Cilj ovog projekta je efikasna evidencija i upravljanje podzemnom i nadzemnom komunalnom infrastrukturom, a kako bi se smanjili troškovi, povećala efikasnost, privukle investicije te pružila bolja usluga`,
    living_info: `Cilj ovog projekta je direktno povezivanje s građanima i životom u gradu te kretanjem stanovnika i posjetitelja – čime se dodaje direktna vrijednost za građane. Područjem Pametan način života obuhvaćeni su projekti vezani uz poboljšanje kvalitete života u raznim elementima – sigurnost, socijalna inkluzija, održivi turizam.`,
    modesty_info: `Središnja razvojna aktivnost projekta Modesty je razvoj Big Data sustava i seta naprednih analitičkih modela. Podaci o stanju u okolišu i aktivnostima stanovništva prikupljat će se u stvarnom vremenu, a sve u svrhu dobivanja što relevantnijeg uzorka podataka za potrebe istraživanja, čime ovaj projekt stvara platformu za međusobnu suradnju grada, industrije, znanstvene zajednice i stanovništva. Pored podataka s hardverskih senzora, prikupljat će se podaci iz mobilne mreže operatora o kretanju i zadržavanju stanovnika.`,
    surinmo_info: `Cilj projekta je razviti platformu za upravljanje mrežama javno dostupnih punionica, te razviti i testirati sustav eZnakova i sustav eRaskrižja gradskog prometnog centra Rijeke koji bi se također povezali u buduću SmartCity.SURINMO platformu. Područje Pametna ekonomija podrazumijeva projekte povezane s unaprjeđenjem poslovanja putem inovacija čiji je rezultat povećanje produktivnosti. Projekti i proizvodi optimiziraju prometne tokove i rasterećuju promet u mirovanju pa se očekuju vidljivi rezultati u kvaliteti prometa, od sigurnosti do informiranosti. Navedeno će se posebice odnositi na slučajeve preusmjeravanja prometa u slučaju nesreća, vremenskih nepogoda, radova na cesti i slično.`,
    connectedTraffic_info: `Projekt Connected.Traffic ima za cilj poboljšati kvalitetu života građana uspostavom kvalitetnijih rješenja u gradskom i multimodalnom prometu te promicanjem i poticanjem održivih, čistih i energetski učinkovitih načina odvijanja prometa. Napredni informacijsko–komunikacijski sustav koji se razvija unutar ovog projekta poslužit će kao podrška odlučivanju te u samom upravljanju urbanom mobilnošću.`,

    // Energy Products Info
    energy_name_one: "Energy Efficiency",
    energy_name_two: "EmPower",
    energy_name_three: "SmartWaste",
    energy_name_four: "KPI Evaluator",
    energy_name_five: "SmartLighting Network",

    energy_info_one: `EnergyEfficiency je platforma koja omogućava praćenje potrošnje energije i energetske učinkovitosti u svim sektorima energetske potrošnje, kao i planiranje provedbe mjera energetske učinkovitosti u pametnom gradu.`,
    energy_info_two: `<b>Smartis</b> je rastuća tehnološka tvrtka koja razvija inovativno rješenje Digital Twin Platform. Riječ je o rješenju koje je u digitalnoj kopiji na jednom mjestu povezalo sve važne fizičke objekte u općini Rijeka i omogućilo praćenje karakteristika zgrade i ključnih energetskih mjerenja u stvarnom vremenu. Uključivanjem različitih dionika u platformu želimo korisnicima ponuditi bolje informacije prilikom donošenja odluka o smanjenju ugljičnog otiska u Smart City Rijeka. Platforma služi i kao podloga za opunomoćenje i aktivaciju krajnjih korisnika energije i ostalih prirodnih resursa, pomoću koje će građani imati mogućnost izravno sudjelovati u razvoju modela energetske učinkovitosti i održivosti pametnog grada.`,
    energy_info_three: `Kroz mobilne i web aplikacije koje razvija Hrvatski Telekom, povezat će se komunalna društva zadužena za zbrinjavanje otpada, dispečeri koji planiraju dnevne rute zbrinjavanja otpadom te  građani.`,
    energy_info_four: `KPI evaluator je inovativna i interaktivna prezentacijska platforma koja na jednom mjestu objedinjava odabrane ključne pokazatelje uspješnosti za praćenje razvoja gradova. KPI evaluator omogućava njihovu interpretaciju i međusobnu usporedbu te gradskim upravama služi kao temelj za kvalitetno donošenje odluka`,
    energy_info_five: `Elektrokovina plus razvija cjelovito rješenje za učinkovito upravljanje sustavom javne rasvjete i prateće infrastrukture.`,

    // Modesty Products Info
    modesty_name_one: `Ceste`,
    modesty_name_two: 'Donte',
    modesty_name_three: 'eNatječaji',
    modesty_name_four: 'eView',
    modesty_name_five: 'Meer',
    modesty_name_six: 'Pametna destinacija',
    modesty_name_seven: 'Picolus',
    modesty_name_eight: 'RiLive',
    modesty_name_nine: 'Teddy',
    modesty_name_ten: 'Votmax',
    modesty_name_eleven: 'Zanty',

    modesty_info_one: `Cilj ove mobilne aplikacije je obavještavanje korisnika u prometu o raznim promjenama u prometu tijekom same vožnje.`,
    modesty_info_two: `Donte je modul koji nam omogućava da generiramo statistički izvještaj o posjetiteljima korisnicima HT mreže na određenoj lokaciji u željenom razdoblju. Izvještaj se bazira na demografskim podacima poput dobi, spola, nacionalnosti, te vremenu provedenom na lokaciji, broju ljudi na lokaciji. Svi podaci koji se koriste u ovom projektu su anonimizirani.`,
    modesty_info_three: `Svrha ove web aplikacije je pružanje obavijesti o gradskim, županijskim i ostalim natječajima.`,
    modesty_info_four: `Ovo je ePogled na pametni grad koji Vam omogućava pregled API-a i otvorene dokumentacije projekata ostvarenih u sklopu CEKOM-a. eView je gradski portal za otvorene podatke, platforma kojom se želi potaknuti suradnja grada, industrije, znanstvene zajednice te stanovništva.`,
    modesty_info_five: `Uz pomoću u potpunosti anonimiziranih podataka iz HT mreže i naprednih algoritama Meer je modul koji nam pokazuje smjer dolaska ljudi na konkretnu lokaciju, brzinu njihova kretanja te koliko su se oni na toj lokaciji zadržali. Također nam pokazuje koliko je ljudi u danom vremenu na određenoj lokaciji. Sve navedeno prikazuje se uz pomoć heat mape. Zeleno je oznaka za manji broj ljudi (‘mrlje’) ili sporije kretanje (strelice), a crveno za veći broj ljudi i veću brzinu kretanja.`,
    modesty_info_six: `Različitim izvještajima utemeljenim na prikupljenim podacima omogućuje se pametno upravljanje destinacijom (Data Driven Destination Management). Ideja je razviti detaljniju analitiku na temelju postojećih i novih izvora podataka, a u svrhu praćenja i optimizacije kretanja domaćih i stranih građana i posjetitelja grada i vizualizacijski prikaz istoga za potrebe donošenja zaključaka u realnom vremenu.`,
    modesty_info_seven: `Picolus je također izvještaj koji omogućuje pregled broja ljudi na lokaciji, no kao izvor podataka koriste se tzv. pico ćelije. One omogućuju nešto veću preciznost u smislu broja ljudi na lokaciji, na granulaciji od 5 do 10 minuta te u radijusu od 150 metara. U RIjeci ima nekoliko pico ćelija koje su nam izvor podataka za ovaj projekt. 
    Nakon odabira vremenskog perioda korisnik odabire lokaciju koju želi razmatrati - iz liste lokacija s pico ćelijom te mu se generira karta i tablica s detaljnim informacijama o broju i strukturi ljudi na lokaciji.`,
    modesty_info_eight: `Cilj ove aplikacije je na jednom mjestu prikazivati podatke od javnog interesa vezane uz život u gradu Rijeci (prognoza, javni prijevoz, promet, zabava i kultura, epidemiološki podaci, novosti…) za dan pretraživanja (iz tog razloga je naziv aplikacije RiLive – Rijeka SADA).`,
    modesty_info_nine: `Teddy je heat mapa koja prikazuje broj korisnika HT mreže na određenom području grada Rijeke. Omogućuje filtriranje prikaza po spolu, tarifi - poslovna ili privatna, po dobi te po nacionalnosti. Također izmjenama vremena u danu koje se promatra vidljivo je u koje doba dana se na kojem mjestu stvaraju veće gužve tj. se nalazi veći broj ljudi. Podaci koji se koriste su u potpunosti anonimizirani.`,
    modesty_info_ten: `Votmax je machine learning projekt. Izrađen je napredni software koji uz pomoć kamere postavljene na prometnici broji vozila te prepoznaje o kojem se tipu vozila radi (automobil, kombi, kamion, motocikl). Moguće je također na isti način pratiti morski promet.`,
    modesty_info_eleven: `Zanty je modul koji koristi podatke prikupljene uz pomoć senzora za mjerenje kvalitete zraka, instalirane od strane CEKOM partnera na raznim djelovima grada Rijeke. Podaci o razini zagađenja zraka na određenoj lokaciji u gradu Rijeci prikazani su na karti, vidljiva je lokacija senzora te je bojom naznačen intenzitet zagađenja. Mjerenja se također prikazuju i tablično.`,

    // Living Products Info
    living_name_one: `Multifunkcionalni uređaj za dvosmjernu komunikaciju građana i Grada`,
    living_name_two: 'eTinerer - city movement',
    living_name_three: 'Barometar sigurnosti',
    living_name_four: 'Rijeka.Info',
    living_name_five: 'Sustav za upravljanje destinacijom',
    living_name_six: 'City Smart View',

    living_info_one: `Koristite multifunkcionalni uređaj Hrvatskog Telekoma za dvosmjernu komunikaciju između građana i grada Rijeke. Uređaj povezuje postojeće telekomunikacijske usluge s ostalim inovativnim uslugama razvijenim u projektu, a aplikacija koju upravo gledate nudi Vam detaljnije informacije te mogućnost preuzimanja razvijenih aplikacija ili posjećivanja web stranice pojedinog projekta putem QR koda.`,
    living_info_two: `U sklopu platforme <b>Tinerer - city movement</b> razvija se mobilna aplikacija <b>etinerary</b> i prateći <b>potporni web</b> za crowd-sourcing podataka.

                <b>etinerary</b> je virtualni osobni pomoćnik koji uz pomoć umjetne inteligencije kroji personalizirane turističke rute. Pritom korisniku daje prijedlog optimalne sljedeće destinacije i konkretne aktivnosti na njoj. Dopušta promjenu planova u bilo kojem trenutku, uvijek je raspoloživ i moguće ga je koristiti potpuno besplatno. Njegov osnovni zadatak je maksimizirati korisnikovo zadovoljstvo destinacijom.
                
                Ponuđači usluga i sadržaja na lokacijama mogu svoju ponudu unijeti putem <b>potpornog weba</b>, također potpuno besplatno, te na taj način usmjeriti korisnike k sebi.`,
    living_info_three: `Kroz aplikaciju se prikupljaju, obrađuju i na karti grada Rijeke prikazuju informacije o raznim događajima koji utječu na sigurnost i kvalitetu života građana: o kriminalitetu, poteškoćama u prometu i parkiranju, kvaliteti zraka i drugim ekološkim pokazateljima te o funkcioniranju sustava opskrbe vodom, strujom i plinom. Prikazani podaci građanima i posjetiteljima služe za odabir sigurnijih i bržih pravaca kretanja, a gradskim službama za utemeljeno prepoznavanje mjesta na kojima je potrebno podići razinu sigurnosti. 
    Aplikacija koristi podatke iz različitih javnih izvora i od strane građana, a razvila ju je Alarm automatika u okviru CEKOM projekta Smart City Living sa ciljem poboljšanja kvalitete života građana Rijeke.
    `,
    living_info_four: `Rijeka.Info je mobilna aplikacija sa podacima o komercijalnim i javnim ponudama grada te njihov prikaz kroz virtualnu realnost.`,
    living_info_five: `iOLAP uz asistenciju Fakulteta za menadžment u turizmu i ugostiteljstvu razvija središnji repozitorij sadržaja i informacija o destinaciji dostupan na više komunikacijskih kanala/resursa za goste na destinaciji. `,
    living_info_six: `City Smart View je aplikacija koju upravo koristite! Njezin je cilj prezentirati Vam sve projekte koji su ostvareni u sklopu CEKOM-a te s ciljem stvaranja pametnog grada. Istražujući o  projektima uz pomoć ove aplikacije imate priliku i skenirati QR kodove te ili pristupiti web stranici određenog projetka ili preuzeti aplikaciiju!`,

    // 4DII Products Info
    dii_name_one: 'Platforma 4DII',
    dii_name_two: 'Sustav za automatsku obradu i prepoznavanje podzemne infrastrukture na osnovi radarskih zapisa',
    dii_name_three: 'Sustav inovativne inventarizacije i kontrole infrastrukture',
    dii_name_four: 'Sustav za nadzor objekata kritične infrastrukture integriran sa naprednim analitičkim alatima',
    dii_name_five: 'Integralni sustav 4D inteligentne infrastrukture',

    dii_info_one: `Inovativni proizvod kojeg razvija Ericsson Nikola Tesla zamjenjuje dvodimenzionalni prikaz infrastrukture prostorno-vremenskom dimenzijom (4D), a dodatno se ubrzava proces prikupljanja i interpretacije podataka, čime se postiže efikasnija evidencija i upravljanje komunalnom infrastrukturom.`,
    dii_info_two: `Istraživanje, formuliranje i validacija koncepta za automatsku obradu radarskih zapisa obuhvaća početak razvoja ICT rješenja koje će omogućiti bržu obradu podataka komunalne infrastrukture. Integralni sustav razvija Sveučilište u Rijeci.`,
    dii_info_three: `3t.cable razvija IKT rješenje koje će omogućiti inovativniju inventarizaciju i kontrolu komunalne infrastrukture (I3Solutions) `,
    dii_info_four: `Alarm Automatika razvija sustav za nadzor objekata kritične infrastrukture, upravljanja u kriznim situacijama i slanja masovnih poruka i obavijesti. `,
    dii_info_five: `Cilj je da sustav Smart City 4DII svojim modulima omogući inventarizaciju, planiranje, upravljanje, dokumentiranje i analiziranje podataka komunalne infrastrukture integracijom različitih aspekata podataka.`,

    // Surinmo Products Info
    surinmo_name_one: 'eRaskrižja',
    surinmo_name_two: 'eRoaming platforma',
    surinmo_name_three: 'eZnakovi',

    surinmo_info_one: `Projekt eRaskrižja koji razvija Smart Sense predstavlja kompleksan sustav visoko povezanih modula na različitim razinama. eRaskrižja će primarno povećati sigurnost pješaka u prometu, a istovremeno će omogućiti pametnije planiranje i upravljanje prometom u urbanim i ostalim sredinama. Naime, osim naprednog praćenja i klasifikacije prometa, kroz sustav će se izvršiti integracija s preostalim pametnim rješenjima poput pametne rasvjete, a bit će razvijeni i protokoli za slanje podataka i alarmiranje te nadzor sustava.`,
    surinmo_info_two: `Hrvatski Telekom razvija platformu s jasno definiranim lokacijama za punjenje e-vozila, uz real-time naznaku dostupnosti i mogućnost rezervacije te korištenja punionice, s uključenim preciznim informacijama te lokacijama za korištenje Car Sharing sustava i potpuno digitaliziranim sustavom parkinga, koji objedinjuje informacije o dostupnim lokacijama za slobodan parking. Zasad u Hrvatskoj, ali i u široj regiji, ne postoji jedinstvena platforma koja može služiti korisnicima parkinga, e-vozila i car sharing sustav, a posljedica implementacije takvog sustava bit će jačanje sigurnosti na cestama te povećanje propusnosti u prometu.`,
    surinmo_info_three: `Exevijev proizvod eZnakovi će implementirati senzorska rješenja koja će sakupljati podatke o okruženju i pružati preduvjete za analizu. Kompletan projekt je baziran na inovaciji odnosno kombinaciji novih tehnologija te nove industrije bazirane na upotrebi eTinte. Nakon izrade prvih primjeraka eZnakova i njihovog postavljanja na lokacije koje će se odrediti sukladno prioritetima Grada, isti će se priključiti na Gradski prometni centar koji će biti nadležan za njihovo upravljanje te ispitivanje u stvarnim operativnim uvjetima.`,

    // Connected Traffic Products Info
    connectedTraffic_name_one: 'Platforma za agregaciju podataka u funkciji odlučivanja u gradskom prometu i urbanoj mobilnosti',
    connectedTraffic_name_two: 'Sustav za nadzor i upravljanje u funkciji odlučivanja u gradskom prometu i urbanoj mobilnosti',
    connectedTraffic_name_three: 'Sustav korištenja i razmjene resursa, infrastrukture i imovine na području prometa korištenjem paradigme ekonomije dijeljenja',
    connectedTraffic_name_four: 'Napredna analitička rješenja za povećanje sigurnosti u sklopu pametnih gradova',
    connectedTraffic_name_five: 'Integrirani sustav za podršku odlučivanju u gradskom prometu i urbanoj mobilnosti',
    
    connectedTraffic_info_one: `Platforma koju razvija Ericsson Nikola Tesla podataka prikuplja podatke u stvarnom vremenu. Rješenje je modularno i skalabilno te omogućava integraciju niza izvora i obradu standardiziranih i nestandardiziranih ulaznih podataka.`,
    connectedTraffic_info_two: `Partneri su razvili niz naprednih analitičkih rješenja za podršku odlučivanju u gradskom prometu i urbanoj mobilnosti. Kroz sustav su definirane nove standardne operativne procedure koje se mogu aktivirati ručno, automatski temeljem raspona zaprimljenih vrijednosti iz platforme za agregaciju podataka i automatski izravnom dojavom.`,
    connectedTraffic_info_three: `Apsolon radi na istraživanju i razvoju sustava zajedničkog korištenja resursa, infrastrukture i imovine kroz paradigmu ekonomije dijeljenja „ShaRIng“, a kao koncept koji će se razvijati u nastavku projekta odabrali su jednostavniji pristup eventima.`,
    connectedTraffic_info_four: `Napredna analitička rješenja usmjerena su na uspostavu procesa koji temeljem identifikacije događaja (poglavito požara), lokacije i drugih parametara, na osnovi utvrđenih vrijednosti izabire novo-definirani skup instrukcija koje treba izvršiti.`,
    connectedTraffic_info_five: `Sustav će prikupljati, obrađivati i učiniti dostupnim sve raspoložive informacije vezane za prometni sustav u cilju boljeg i kvalitetnijeg upravljanja prometom i održivom mobilnošću u sklopu pametnih gradova. Inovativnost je sadržana u posve novom pristupu povezivanju i analitici velikih podataka te njihovoj uporabi u realnom vremenu za upravljanje prometnim tokovima.`,

}