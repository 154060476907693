<template>
    <v-btn @click="$router.push('/')" class="py-10 float-home-btn back-btn">
        <img class="my-4 mx-4" height="50px" :src="require(`@/assets/buttons/home.svg`)" />
    </v-btn>
</template>

<script>
export default {
    name: "HomeButton",
}
</script>

<style scoped>
.float-home-btn {
	position: fixed;
	bottom: 40px;
    left: 60px;
    background-color: #FFF !important;
    z-index: 5;
}
</style>
